import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  testPaperId: '',
};

const testPaperSlice = createSlice({
  name: "testPaper",
  initialState: initialState,
  reducers: {
    setTestPaperId(state, action) {
      state.testPaperId = action.payload;
    },
  },
});

export const { setTestPaperId } = testPaperSlice.actions;
export default testPaperSlice.reducer;
