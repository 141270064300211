import axios from "axios";

import { BASE_URL } from "../../Constants";

export const AxiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
  // timeout: 2000,
});
AxiosInstance.interceptors.response.use(
  async (response) => {
    if (response.data.code === 401) {
      const originalRequest = response.config;
      console.log("originalRequest._isRetry", originalRequest._isRetry);
      if (
        response.data.code === 401 &&
        originalRequest &&
        !originalRequest._isRetry
      ) {
        originalRequest._isRetry = true;
        try {
          const { guid } = JSON.parse(localStorage.getItem("user"));

          const refreshToken = await AxiosInstance.get(
            "/user/refresh-token",
            { params: { userGuid: guid } },
            {
              withCredentials: true,
            }
          );
          if (refreshToken.data.code !== 200) {
            localStorage.clear();
          }

          return AxiosInstance.request(originalRequest);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 400) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export const getNewsAxios = async () =>
  AxiosInstance.get("/dashboard/news").then((data) => data.data);

export const getJSONAxios = async (data) =>
  AxiosInstance.get("/web/json", { params: data }).then((res) => res.data);

export const saveQueriesAxios = async (data) =>
  AxiosInstance.post("/queries", data).then((res) => res.data);

export const getPresignedUrlAxios = async (data) =>
  AxiosInstance.post("/dashboard/store", data).then((res) => res.data);

export const uploadFileAxios = async ({ url, file, type }) => {
  try {
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": type,
      },
      body: file,
    });
    return null;
  } catch (error) {
    return error;
  }
};

export const getReadUrl = async (data) =>
  AxiosInstance.get("/dashboard/store", { params: data }).then(
    (res) => res.data
  );
