import { useDispatch } from "react-redux";
import { setModalStep } from "../../../store/webSlice";
import ModalComp from "../../../Components/ModalComp";
import { useNavigate } from "react-router-dom";

function PaymentSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancelClick = () => {
    dispatch(setModalStep(0));
    navigate("/student/dashboard");
  };

  const actions = {
    cancel: handleCancelClick,
  };
  const textData = {
    cancelBtn: "Close",
  };
  const styles = {
    width: "600px",
  };

  return (
    <div>
      <ModalComp text={textData} actions={actions} styles={styles}>
        <p className="text-2xl font-bold mb-8">Success Confirmation</p>
        <div className="flex flex-col justify-center items-center">
          <img
            src="/assets/payment-success.png"
            alt="success"
            className="h-48 w-48"
          />
          <p className="mt-5 text-lg">Product Purchased Successfully!</p>
        </div>
      </ModalComp>
    </div>
  );
}

export default PaymentSuccess;
