import { lazy, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FileUpload from "../Pages/StudentDashboard/screens/FileUpload";
import PaymentSuccess from "../Pages/StudentDashboard/screens/PaymentSuccess";

export const Navbar = lazy(() => import("../Components/Navbar"));
export const Home = lazy(() => import("../Pages/Home"));
export const Login = lazy(() => import("../Pages/Login"));
export const Register = lazy(() => import("../Pages/Register"));
export const ForgotPassword = lazy(() =>
  import("../Pages/Login/Screens/ForgotPassword")
);
export const OtpVerification = lazy(() =>
  import("../Pages/Login/Screens/OtpVerification")
);
export const ChangePassword = lazy(() =>
  import("../Pages/Login/Screens/ChangePassword")
);
export const Schedules = lazy(() => import("../Pages/Schedules"));
export const Sidebar = lazy(() => import("../Components/Dashboard/Sidebar"));
export const TestPapers = lazy(() =>
  import("../Pages/StudentDashboard/TestPapers")
);
export const StudentHome = lazy(() =>
  import("../Pages/StudentDashboard/StudentHome")
);
export const TestAnswers = lazy(() =>
  import("../Pages/StudentDashboard/TestAnswers")
);
export const SeriesSchedules = lazy(() =>
  import("../Pages/StudentDashboard/SeriesSchedules")
);
export const BuyTestSeries = lazy(() =>
  import("../Pages/StudentDashboard/BuyTestSeries")
);
export const PurchasedTestSeries = lazy(() =>
  import("../Pages/StudentDashboard/PurchasedTestSeries")
);
export const Orders = lazy(() => import("../Pages/StudentDashboard/Orders"));
export const Invoice = lazy(() => import("../Pages/StudentDashboard/Invoice"));
export const Cart = lazy(() => import("../Pages/StudentDashboard/Cart"));
export const CheckoutConfirmation = lazy(() =>
  import("../Pages/StudentDashboard/screens/CheckoutConfirmation")
);
export const Profile = lazy(() => import("../Pages/StudentDashboard/Profile"));
export const UpdatePassword = lazy(() =>
  import("../Pages/StudentDashboard/screens/UpdatePassword")
);
export const ProfileUpdateConfirmation = lazy(() =>
  import("../Pages/StudentDashboard/screens/ProfileUpdateConfirmation")
);
export const TermsConditions = lazy(() => import("../Pages/TermsConditions"));
export const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
export const RefundCancellation = lazy(() =>
  import("../Pages/RefundCancellation")
);

export const RouterComp = () => {
  const [user, setUser] = useState(localStorage.getItem("user"));

  const isAuthenticated = !!user;

  const BlankComp = "";
  const stepComp = {
    0: BlankComp,
    1: ForgotPassword,
    2: OtpVerification,
    3: ChangePassword,
    4: CheckoutConfirmation,
    5: UpdatePassword,
    6: ProfileUpdateConfirmation,
    7: FileUpload,
    8: PaymentSuccess,
  };
  const step = useSelector((data) => data.web.modalStep);
  const Step = stepComp[step];

  return (
    <BrowserRouter>
      {step !== 0 ? <Step /> : ""}
      <Navbar />
      <Sidebar />
      <Routes>
        <Route
          path="/"
          element={
            <div className="App text-black min-h-screen">
              <Home />
            </div>
          }
        />
        <Route
          path="/login"
          element={
            !isAuthenticated ? (
              <Login setUser={setUser} />
            ) : (
              <Navigate to="/student/dashboard" replace />
            )
          }
        />
        <Route
          path="/register"
          element={
            !isAuthenticated ? (
              <Register />
            ) : (
              <Navigate to="/student/dashboard" replace />
            )
          }
        />
        <Route path="/schedules" element={<Schedules />} />
        <Route path="/t&c" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund&cancellation" element={<RefundCancellation />} />
        {isAuthenticated ? (
          <Route path="/student/dashboard">
            <Route path="" element={<StudentHome />} />
            <Route path="buy-test-series" element={<BuyTestSeries />} />
            <Route path="test-papers" element={<TestPapers />} />
            <Route path="test-answers" element={<TestAnswers />} />
            <Route path="schedules" element={<SeriesSchedules />} />
            <Route path="purchased-series" element={<PurchasedTestSeries />} />
            <Route path="orders" element={<Orders />} />
            <Route path="invoice" element={<Invoice />} />
            <Route path="cart" element={<Cart />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComp;
