import { AxiosInstance } from "./index";

export const getAllProductsAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/product", { params: data }).then(
    (data) => data.data
  );
};

export const addToCartAxios = async (data) => {
  return await AxiosInstance.post("/dashboard/order", data).then(
    (data) => data.data
  );
};

export const removeFromCartAxios = async (data) => {
  return await AxiosInstance.delete("/dashboard/order", { params: data }).then(
    (data) => data.data
  );
};

export const getPurchasedOrdersAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/order", { params: data }).then(
    (data) => data.data
  );
};

export const getSchedulesAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/schedule", { params: data }).then(
    (data) => data.data
  );
};

export const getTestPapersAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/test-paper", {
    params: data,
  }).then((data) => data.data);
};

export const uploadTestAnswersAxios = async (data) => {
  return await AxiosInstance.post("/dashboard/test-answer", data).then(
    (data) => data.data
  );
};

export const getTestAnswersAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/test-answer", {
    params: data,
  }).then((data) => data.data);
};

export const getTransactionsAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/transaction", {
    params: data,
  }).then((data) => data.data);
};

export const initiatePaymentAxios = async (data) => {
  return await AxiosInstance.post("/dashboard/payment/initiate", data).then(
    (data) => data.data
  );
};

export const paymentCallbackAxios = async (data) => {
  return await AxiosInstance.post("/dashboard/payment/callback", data).then(
    (data) => data.data
  );
};

export const getUserPromoAxios = async (data) => {
  return await AxiosInstance.post("/dashboard/promo/by-user", data).then(
    (data) => data.data
  );
};

export const isValidPromoAxios = async (data) => {
  return await AxiosInstance.get("/dashboard/promo/is-valid", {
    params: data,
  }).then((data) => data.data);
};

export const analyticsAxios = async () => {
  return await AxiosInstance.get("/dashboard/analytics/user").then(
    (data) => data.data
  );
};
