import { useRef, useEffect } from "react";
function ModalComp({ styles, text, actions, children }) {
  const modalRef = useRef(null);

  const width = styles?.width ? styles?.width : "400px";

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click outside the modal, trigger close action
        actions.cancel();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.body.style.overflow = "hidden"; // Prevent scrolling

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = ""; // Re-enable scrolling when modal is closed
    };
  }, [actions]);
  return (
    <div className="fixed h-screen w-screen flex items-center justify-center z-50 bg-[rgba(0,0,0,0.6)]">
      <div
        ref={modalRef}
        className={`bg-white p-4 text-center w-[${width}] z-20 shadow-xl border-2 border-black rounded-xl`}
        style={{ width }}
      >
        {children}
        {text?.cancelBtn && (
          <button
            onClick={actions.cancel}
            className="mt-6 mx-1 bg-bg-color-secondary text-text-color border-2 border-text-color py-2 px-6 rounded-lg font-semibold"
          >
            {text?.cancelBtn}
          </button>
        )}
        {text?.submitBtn && (
          <button
            onClick={actions.submit}
            className="mt-6 mx-1 bg-primary-color border-2 border-transparent text-text-color-secondary py-2 px-6 rounded-lg font-semibold"
          >
            {text?.submitBtn}
          </button>
        )}
      </div>
    </div>
  );
}

export default ModalComp;
