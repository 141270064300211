import { configureStore } from "@reduxjs/toolkit";
import webSlice from "./webSlice";
import cartSlice from "./cartSlice";
import userSlice from "./userSlice";
import testPaperSlice from "./testPaperSlice";

export const store = configureStore({
  reducer: {
    web: webSlice,
    cart: cartSlice,
    user: userSlice,
    testPaper: testPaperSlice,
  },
});
