import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { validImageType } from "../../../Constants";
import ModalComp from "../../../Components/ModalComp";
import { setModalStep } from "../../../store/webSlice";
import { getPresignedUrlAxios } from "../../../helpers/axiosApi/index";
import { uploadFileAxios } from "../../../helpers/axiosApi/index";
import { uploadTestAnswersAxios } from "../../../helpers/axiosApi/dashboard";
import { setTestPaperId } from "../../../store/testPaperSlice";

export default function FileUpload() {
  const queryClient = useQueryClient();

  const { testPaperId } = useSelector((state) => state.testPaper);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [type, setType] = useState("");
  const [answerData, setAnswerData] = useState({
    submittedAnswer: "",
    testPaperId,
  });

  const handleCancelClick = () => {
    dispatch(setModalStep(0));
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    const fileExtension = `.${selectedFile.name
      .split(".")
      .pop()
      .toLowerCase()}`;

    if (validImageType.includes(fileExtension)) {
      setFile(e.target.files[0]);
      setType(selectedFile.type);
      setAnswerData({
        ...answerData,
        submittedAnswer: selectedFile?.name.split(".")[0],
      });
      const fileInfo = {
        fileType: selectedFile.type,
        fileName: selectedFile?.name.split(".")[0],
        fileSize: selectedFile?.size,
        type: "test-answer",
      };
      getPresignedMutation.mutate(fileInfo);
    } else {
      toast.error("Invalid file type. Please upload a valid file.");
    }
  };
  const getPresignedMutation = useMutation({
    mutationFn: async (data) => getPresignedUrlAxios(data),
    onSuccess: async (data) => {
      uploadFileAxios({ url: data?.data.url, file, type });
    },
  });

  const updateTestAnswerMutation = useMutation({
    mutationFn: async (data) => uploadTestAnswersAxios(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["addedTestAnswers"] });
      dispatch(setModalStep(0));
      dispatch(setTestPaperId(""));
      navigate("/student/dashboard/test-answers");
    },
  });

  const handleSubmitClick = () => {
    //api call to update profile
    if (!file) toast.error("Upload file first");
    else updateTestAnswerMutation.mutate(answerData);
  };

  const actions = {
    cancel: handleCancelClick,
    submit: handleSubmitClick,
  };
  const textData = {
    cancelBtn: "Cancel",
    submitBtn: "Proceed",
  };
  const styles = {
    width: "400px",
  };

  return (
    <div>
      <ModalComp text={textData} actions={actions} styles={styles}>
        <p className="text-2xl font-bold mb-8">Upload File</p>
        <input type="file" onChange={handleFileChange} />
      </ModalComp>
    </div>
  );
}
