import { createSlice } from "@reduxjs/toolkit";

const userData = localStorage.getItem("user");
const localStorageUserData = userData ? JSON.parse(userData) : {};

const initialState = {
  user: {
    name: localStorageUserData?.name || "",
    email: localStorageUserData?.email || "",
    id: localStorageUserData?.id || "",
    mobile: localStorageUserData?.mobile || "",
  },
  encryptedOtp: "",
  otpState: "",
  emailState: "",
  updateData: {},
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    saveUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    saveEncryptedOtp(state, action) {
      state.encryptedOtp = action.payload;
    },
    resetUser(state, action) {
      state.user = initialState.user;
    },
    saveOtpState(state, action) {
      state.otpState = action.payload;
    },
    setEmailState(state, action) {
      state.emailState = action.payload;
    },
    setUpdateData(state, action) {
      state.updateData = action.payload;
    },
  },
});

export const {
  saveEncryptedOtp,
  saveUser,
  saveOtpState,
  resetUser,
  setEmailState,
  setUpdateData,
} = userSlice.actions;
export default userSlice.reducer;
