import { Suspense, useEffect } from "react";
import "./App.css";
import RouterComp from "./Routers/RouterComp";
import Loader from "./Components/loader/Loader";
import { useSelector } from "react-redux";


function App() {

  const loading = useSelector((state) => state.web.loading);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>{loading && <Loader />}
      <Suspense fallback={<Loader />}>
        <RouterComp />
      </Suspense>
    </>
  );
}

export default App;
