import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cartData: [],
  cartTotal: 0,
  promoData: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    setCartData(state, action) {
      state.cartData = action.payload;
    },
    setCartTotal(state, action) {
      state.cartTotal = action.payload;
    },
    setPromoData(state, action) {
      state.promoData = action.payload;
    },
  },
});

export const { setCartData, setCartTotal, setPromoData } = cartSlice.actions;
export default cartSlice.reducer;
