import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  modalStep: 0,
  previousModalStep: 0,
  isFaq: false,
  loading: false,
};

const webSlice = createSlice({
  name: "web",
  initialState: initialState,
  reducers: {
    setModalStep(state, action) {
      state.modalStep = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPreviousModalStep(state, action) {
      state.previousModalStep = action.payload;
    },
    setIsFaq(state, action) {
      state.isFaq = action.payload;
    },
  },
});

export const { setModalStep, setPreviousModalStep, setIsFaq, setLoading } =
  webSlice.actions;
export default webSlice.reducer;
